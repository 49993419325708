import { Avatar, Button, CircularProgress, IconButton } from "@mui/material";
import React, { useState, useRef, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import {
  chatBotIcon,
  editIcon,
  resendIcon,
  trashModalIcon,
} from "../../../../../assets";
import { openModal } from "../../../../../slices/modals";
import data from "../../../../../utilities/constants";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import DoneIcon from "@mui/icons-material/Done";
import {
  resendMessageOperator,
  updateMessage,
} from "../../../../../slices/messages";
import FileMessage from "../../../../../components/FileMessage";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { changeLastMessageinConversation } from "../../../../../slices/conversation";
import { isIframe, parseHtml } from "../../../../../utilities/ParseHtmlToText";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw, Modifier } from "draft-js";
import { UploadImage } from "../../../../../slices/helpdesk";
import Autolinker from "autolinker";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../../../../slices/notifier";
import { isImage } from "../../../../../utilities/isImage";
import { embedVideoCallBack } from "../../../../../utilities/embedVideoCallback";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { ConvertingArabicNumberToEnglish } from "../../../../../utilities/toEnglishNumber";

const Message = React.memo(
  ({ message, state, user, setTodo, firstMessage }) => {
    const dispatch = useDispatch();
    if (message?.from === "BOT") {
      message = {
        ...message,
        content: message?.question
          ? message?.question?.content
          : message?.content,
      };
    }

    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    const { t } = useTranslation();
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
    const showSnackbar = (data) => {
      // NOTE:
      // if you want to be able to dispatch a `closeSnackbar` action later on,
      // you SHOULD pass your own `key` in the options. `key` can be any sequence
      // of number or characters, but it has to be unique for a given snackbar.
      enqueueSnackbar({
        message: t(data.message),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: data.variant,
          action: (key) => (
            <Button
              style={{ color: "white" }}
              onClick={() => closeSnackbar(key)}
            >
              {t("dismiss me")}
            </Button>
          ),
        },
      });
    };

    const [isHovering, setIsHovering] = useState(false);
    const [editedFile, setEditedFile] = useState(null);
    const [edit, setEdit] = useState(false);
    const scrollRef = useRef();
    const { clientConversationStored } = useSelector(
      (state) => state.conversations
    );
    const {
      messagesStored,
      statusSendMessage,
      createNoteInConversation,
      statusUpdateMessage,
      statusResendMessage,
    } = useSelector((state) => state.messages);

    const breakLiner = (string) => {
      return (
        <div
          className={` ${
            message?.isNote ? "is-note-paragraph" : "message-content"
          }`}
          dangerouslySetInnerHTML={{
            __html: string,
          }}
        />
      );
    };
    const handleMouseOver = () => {
      setIsHovering(true);
    };
    const handleMouseOut = () => {
      setIsHovering(false);
    };

    const handleUploadEditedPhoto = (e) => {
      setEditedFile(e.target.files[0] ? e.target.files[0] : null);
    };
    const previousMessageFromTheSameUser = (messages, message) => {
      let messageFromTheSameUser = false;
      message?.from === "OPERATOR" || message?.isNote
        ? (() => {
            messages.forEach((item, index) => {
              item?._id === message?._id &&
                messages[index + 1]?.createdBy?._id ===
                  message?.createdBy?._id &&
                (messageFromTheSameUser = true);
            });
            return messageFromTheSameUser;
          })()
        : message?.from === "CLIENT" &&
          (() => {
            messages.forEach((item, index) => {
              item?._id === message?._id &&
                messages[index - 1]?.from === message?.from &&
                (messageFromTheSameUser = true);
            });
            return messageFromTheSameUser;
          })();
      return messageFromTheSameUser;
    };
    useEffect(() => {
      state?.messageID?._id === message?._id &&
        (() => {
          scrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          });
        })();
    }, [state]);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const htmlData = useMemo(() => {
      const rawContent = convertToRaw(editorState?.getCurrentContent());
      const html = draftToHtml(rawContent);
      const linkedHtml = Autolinker.link(html, { newWindow: true });
      return linkedHtml;
    }, [editorState]);

    const contentBlock = htmlToDraft(
      "<p>" + message?.content?.replace(/<p>(\s|&nbsp;)*<\/p>/g, "") + "</p>" ||
        ""
    );

    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );

    const editorStateContent = EditorState.createWithContent(contentState);
    useEffect(() => {
      setEditorState(editorStateContent);
    }, [message.content]);
    const handleUpdate = (messageId) => {
      dispatch(
        updateMessage({
          webSiteId: user.webSiteId,
          messageId,
          editedMessage: htmlData,
          editedFile,
          conversationId: clientConversationStored?._id,
          oldFile: message?.file,
          oldMessage: message?.content,
        })
      );
      setEdit(false);
    };

    const onEditorStateChange = (val) => {
      setEditorState(val);
    };
    const resendMessage = (newMessage, messageFileObject, messageId) => {
      dispatch(
        resendMessageOperator({
          content: newMessage,
          conversation: clientConversationStored?._id,
          websiteId: user?.websiteID,
          from: "OPERATOR",
          file: messageFileObject,
          filetype: messageFileObject?.type,
          _id: messageId,
          firstName: user?.firstName,
          availability: { status: user?.availability?.status },
          createdBy: { _id: user?._id, avatar: user?.avatar },
          createdAt: new Date(Date.now()),
          user: user,
          not_assigned:
            typeof clientConversationStored?.assigned?.user !== "object"
              ? true
              : false,
          clientConversationStored,
        })
      ).then((res) => {
        if (!res?.error) {
          dispatch(changeLastMessageinConversation(res.payload.data));
        } else {
          showSnackbar({
            variant: "error",
            message: res?.error?.message,
          });
        }
      });
    };

    const uploadImageCallBack = (file) => {
      return new Promise((resolve, reject) => {
        dispatch(
          UploadImage({
            websiteID: user.websiteID,
            file,
          })
        ).then((res) => {
          resolve({ data: { link: res.payload } });
        });
      });
    };

    let inputRef;
    const handleReturn = (event) => {
      // Your handleNewLine function imported from this package
      // Maintain content of the "data" property of the current block when splitting to create the
      // new block (instead of draftjs normal behavior of not maintaining any part of data).
      // This is adapted from https://github.com/facebook/draft-js/issues/723#issuecomment-367918580
      event.preventDefault();
      if (event.shiftKey) {
        return "not-handled";
      }

      const newContentState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        "\n"
      );

      onEditorStateChange(
        EditorState.push(editorState, newContentState, "insert-characters")
      );

      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const startKey = selection.getStartKey();
      const currentBlock = contentState.getBlockForKey(startKey);
      if (currentBlock) {
        const blockData = currentBlock.getData();
        if (blockData) {
          const newContentState = Modifier.splitBlock(contentState, selection);
          const splitState = EditorState.push(
            editorState,
            newContentState,
            "split-block"
          );
          const splitStateWithData = Modifier.mergeBlockData(
            splitState.getCurrentContent(),
            splitState.getSelection(),
            blockData
          );
          const finalState = EditorState.push(
            editorState,
            splitStateWithData,
            "split-block"
          );
          onEditorStateChange(finalState);
          return true;
        }
      }
      return true;
    };

    return (
      <div key={message._id}>
        <div
          className={
            message.from === "OPERATOR" ||
            message.from === "BOT" ||
            message?.from === "AI" ||
            message?.isNote
              ? "message own"
              : "message"
          }
        >
          <div
            className={
              message.from === "OPERATOR" ||
              message.from === "BOT" ||
              message?.from === "AI" ||
              message?.isNote
                ? ` ${
                    message?.isNote ? "message-note-top-own" : "message-top-own"
                  } `
                : "message-top"
            }
            style={{
              paddingTop: message?._id === messagesStored[0]?._id && "30px",
              paddingTop: message?._id === messagesStored[0]?._id && "30px",
              paddingBottom: edit && "20px",
              paddingRight:
                previousMessageFromTheSameUser(messagesStored, message) &&
                (message.from === "BOT" ||
                  message?.from === "AI" ||
                  (message?.from === "OPERATOR" &&
                    message?._id !== messagesStored[0]?._id) ||
                  message?.isNote) &&
                "70px",
              paddingLeft:
                previousMessageFromTheSameUser(messagesStored, message) &&
                !message?.fromMeta &&
                message?._id !== messagesStored[0]._id &&
                message?._id !== messagesStored[0]._id &&
                message.from === "CLIENT" &&
                "70px",
            }}
          >
            {(statusSendMessage === "loading" ||
              createNoteInConversation === "loading") &&
            message?._id === messagesStored[messagesStored.length - 1]._id ? (
              <CircularProgress className="circular-progress-send-message" />
            ) : (
              (!previousMessageFromTheSameUser(messagesStored, message) ||
                message?._id === messagesStored[0]._id ||
                message?.fromMeta) && (
                <Tooltip
                  title={
                    message?.from === "OPERATOR"
                      ? (() => {
                          return message?.createdBy?.firstName
                            ? `${message?.createdBy?.firstName} ${message?.createdBy?.lastName}`
                            : t("Deleted User");
                        })()
                      : ""
                  }
                >
                  <span>
                    <Avatar
                      className={
                        message.from === "BOT" || message?.from === "AI"
                          ? "message-img bot"
                          : "message-img"
                      }
                      src={
                        message.from === "OPERATOR"
                          ? message?.createdBy?.avatar
                          : message.from === "BOT" || message?.from === "AI"
                          ? chatBotIcon
                          : clientConversationStored?.meta?.avatar
                      }
                    />
                  </span>
                </Tooltip>
              )
            )}
            {edit && (message.from === "OPERATOR" || message.from === "BOT") ? (
              <div className="edit-text-container">
                <Editor
                  editorState={editorState}
                  handleReturn={handleReturn}
                  placeholder="Send Your Message..."
                  toolbar={{
                    options: [
                      "inline",
                      "embedded",
                      "emoji",
                      "image",
                      "textAlign",
                      //   "link",
                    ],
                    embedded: {
                      embedCallback: embedVideoCallBack,
                    },
                    inline: { inDropdown: false },
                    image: {
                      uploadCallback: uploadImageCallBack,
                      previewImage: true,
                      defaultSize: {
                        height: "100px",
                        width: "100px",
                      },
                      inputAccept: "image/*",
                    },
                    textAlign: { inDropdown: false },
                    blockType: { inDropdown: false },
                    link: { inDropdown: false },
                  }}
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                />

                <div className="edit-actions">
                  {editedFile && (
                    <div className="edit-image-container">
                      <div onClick={() => setEditedFile(null)}>
                        <HighlightOffOutlinedIcon className="delete-edited-image-icon" />
                      </div>
                      <FileMessage
                        file={
                          !editedFile?.type
                            ? editedFile
                            : URL.createObjectURL(editedFile)
                        }
                        fileType={
                          !editedFile?.type
                            ? message?.filetype
                            : (() => {
                                return ["audio", "video", "image"].includes(
                                  editedFile?.type?.split("/")[0]
                                )
                                  ? editedFile?.type?.split("/")[0]
                                  : editedFile?.type?.split("/")[1];
                              })()
                        }
                        alt="edited-image"
                        className="edited-image"
                      />
                    </div>
                  )}
                </div>
                <div className="edit-icons">
                  <div className="upload-file">
                    <button
                      className="upload-file-btn"
                      onClick={() => {
                        inputRef.click();
                      }}
                    >
                      <AddIcon fontSize="small" />
                    </button>
                    <input
                      type="file"
                      name="file"
                      id="upload-file"
                      accept=".jpg, .png, .jpeg, .svg, .gif, .pdf, .doc, .docx, .txt, .wmv, .mp4, .mov, .mp3"
                      ref={(refParam) => (inputRef = refParam)}
                      onChange={handleUploadEditedPhoto}
                    />
                  </div>
                  <IconButton
                    onClick={() => handleUpdate(message._id)}
                    disabled={
                      (htmlData
                        ?.replace(/<p>(\s|&nbsp;)*<\/p>/g, "")
                        ?.trim() ===
                        message?.content
                          ?.replace(/<p>(\s|&nbsp;)*<\/p>/g, "")
                          ?.trim() &&
                        editedFile === message?.file) ||
                      (!editedFile &&
                        convertToRaw(editorState.getCurrentContent()).blocks
                          .length === 1 &&
                        convertToRaw(editorState.getCurrentContent()).blocks[0]
                          .text === "")
                    }
                  >
                    <DoneIcon
                      style={{
                        color:
                          (htmlData
                            ?.replace(/<p>(\s|&nbsp;)*<\/p>/g, "")
                            ?.trim() ===
                            message?.content
                              ?.replace(/<p>(\s|&nbsp;)*<\/p>/g, "")
                              ?.trim() &&
                            editedFile === message?.file) ||
                          (!editedFile &&
                            convertToRaw(editorState.getCurrentContent()).blocks
                              .length === 1 &&
                            convertToRaw(editorState.getCurrentContent())
                              .blocks[0].text === "")
                            ? "gray"
                            : "#7BC600",
                      }}
                    />
                  </IconButton>
                  <IconButton onClick={() => setEdit(false)}>
                    <NotInterestedIcon style={{ color: "#ED3863" }} />
                  </IconButton>
                </div>
              </div>
            ) : (
              <React.Fragment>
                <Tooltip
                  title={`${t("Sent At")} ${ConvertingArabicNumberToEnglish(
                    moment(message?.createdAt).format("hh:mm ")
                  )} 
                ${t("On")}                     ${new Date(message?.createdAt)
                    .toLocaleDateString
                    // localStorage.getItem("i18nextLng")
                    ()}`}
                  placement={"left-start"}
                >
                  <div
                    dir="auto"
                    id={message._id}
                    onMouseEnter={handleMouseOver}
                    onMouseLeave={handleMouseOut}
                    style={{
                      width: message?.file && "50%",
                    }}
                    className={
                      state?.messageID?._id === message?._id
                        ? message?.from === "CLIENT"
                          ? "message-text-dashed-client"
                          : "message-text-dashed-operator"
                        : message?.error
                        ? "message-text-dashed-error-client"
                        : `${
                            message?.isNote
                              ? "message-text is-note"
                              : "message-text"
                          } `
                    }
                    ref={scrollRef}
                  >
                    {message.from === "OPERATOR" && (
                      <div className="edit-text">
                        {statusUpdateMessage === "loading" &&
                        message?._id ===
                          messagesStored[messagesStored?.length - 1]?._id ? (
                          <CircularProgress className="circular-progress-update-message" />
                        ) : (
                          message?.history?.length > 0 && <span>Edited</span>
                        )}
                      </div>
                    )}
                    <div
                      dir="auto"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        position: "relative",
                        padding: message?.isNote ? "5px" : 0,
                      }}
                    >
                      {message?.isNote && (
                        <BookmarkIcon className="bookmark-icon-style" />
                      )}
                      {message?.isNote && (
                        <span className="note-dropper-name">
                          {`${message?.createdBy?.firstName}  ${t(
                            "left this private note:"
                          )}`}
                        </span>
                      )}

                      {message?.content && message?.file ? (
                        <React.Fragment>
                          <FileMessage
                            className="message-file"
                            file={message?.file}
                            fileType={message?.filetype}
                          />
                          {parseHtml(message.content).includes("+?+?+")
                            ? parseHtml(message.content)
                                .split("+?+?+")
                                .map((item, idx) => {
                                  if (isImage(item)) {
                                    return (
                                      <FileMessage
                                        className="message-file"
                                        file={item}
                                        fileType={"image"}
                                        key={idx}
                                      />
                                    );
                                  } else {
                                    return (
                                      <div key={idx}>{breakLiner(item)}</div>
                                    );
                                  }
                                })
                            : isIframe(message.content).includes("#?#?#")
                            ? isIframe(message.content)
                                .split("#?#?#")
                                .map((item, idx) => {
                                  if (item.includes("https")) {
                                    return (
                                      <FileMessage
                                        className="message-file"
                                        file={item}
                                        fileType={"iframe"}
                                        key={idx}
                                      />
                                    );
                                  } else {
                                    return (
                                      <div key={idx}>{breakLiner(item)}</div>
                                    );
                                  }
                                })
                            : breakLiner(message.content)}
                        </React.Fragment>
                      ) : message?.file && !message?.content ? (
                        <React.Fragment>
                          <FileMessage
                            className="message-file"
                            file={message?.file}
                            fileType={message?.filetype}
                          />
                        </React.Fragment>
                      ) : parseHtml(message.content).includes("+?+?+") ? (
                        parseHtml(message.content)
                          .split("+?+?+")
                          .map((item, idx) => {
                            if (isImage(item)) {
                              return (
                                <FileMessage
                                  className="message-file"
                                  file={item}
                                  fileType={"image"}
                                  key={idx}
                                />
                              );
                            } else {
                              return <div key={idx}>{breakLiner(item)}</div>;
                            }
                          })
                      ) : isIframe(message.content).includes("#?#?#") ? (
                        isIframe(message.content)
                          .split("#?#?#")
                          .map((item, idx) => {
                            if (item.includes("https")) {
                              return (
                                <FileMessage
                                  className="message-file"
                                  file={item}
                                  fileType={"iframe"}
                                  key={idx}
                                />
                              );
                            } else {
                              return <div key={idx}>{breakLiner(item)}</div>;
                            }
                          })
                      ) : (
                        breakLiner(message.content)
                      )}
                    </div>
                    {isHovering &&
                      !state &&
                      !firstMessage &&
                      ((!message?.error &&
                        user?._id === message?.createdBy?._id) ||
                        ["ADMIN", "SUPER-ADMIN"].includes(
                          user?.role?.code
                        )) && (
                        <div className="hover-message-container">
                          {data.HOVER_MESSAGE_ICONS.map((item, index) => {
                            if (
                              message?._id ===
                                messagesStored[messagesStored?.length - 1]
                                  ?._id &&
                              (message?.from === "OPERATOR" || message?.isNote)
                            ) {
                              return ["SUPER-ADMIN", "ADMIN"].includes(
                                user?.role?.code
                              )
                                ? (() => {
                                    return message?.isNote ? (
                                      index !== 0 && (
                                        <LazyLoadImage
                                          src={item.icon}
                                          key={item.id}
                                          alt="icon"
                                          onClick={() => {
                                            if (index === 0) {
                                              setEdit(true);
                                              message?.file &&
                                                setEditedFile(message?.file);
                                              setIsHovering(false);
                                            }
                                            if (index === 2) {
                                              setTodo(message);
                                            }
                                            if (index === 1) {
                                              dispatch(
                                                openModal("info-modal", {
                                                  id: "edit-message-history",
                                                  messages: message?.history,
                                                  icon: editIcon,
                                                  title: t(
                                                    "Edit Message History"
                                                  ),
                                                  description: (
                                                    <React.Fragment>
                                                      {t(
                                                        "You can see all the messages before the last edit"
                                                      )}
                                                    </React.Fragment>
                                                  ),
                                                })
                                              );
                                            }

                                            index === 3 &&
                                              dispatch(
                                                openModal("delete-modal", {
                                                  id: "delete-message",
                                                  messageId: message._id,
                                                  messages: messagesStored,
                                                  websiteID: user?.websiteID,
                                                  icon: trashModalIcon,
                                                  title: t(
                                                    "Are you sure to delete this message?"
                                                  ),
                                                  description: (
                                                    <React.Fragment>
                                                      {t(
                                                        "by clicking on delete message this message will"
                                                      )}
                                                      <span>
                                                        &nbsp;{t("Permanently")}
                                                        &nbsp;
                                                      </span>
                                                      {t(
                                                        "deleted and you will not be able to recover it!"
                                                      )}
                                                    </React.Fragment>
                                                  ),
                                                })
                                              );
                                          }}
                                        />
                                      )
                                    ) : (
                                      <LazyLoadImage
                                        src={item.icon}
                                        key={item.id}
                                        alt="icon"
                                        onClick={() => {
                                          if (index === 0) {
                                            setEdit(true);
                                            message?.file &&
                                              setEditedFile(message?.file);
                                            setIsHovering(false);
                                          }
                                          if (index === 2) {
                                            setTodo(message);
                                          }
                                          if (index === 1) {
                                            dispatch(
                                              openModal("info-modal", {
                                                id: "edit-message-history",
                                                messages: message?.history,
                                                icon: editIcon,
                                                title: t(
                                                  "Edit Message History"
                                                ),
                                                description: (
                                                  <React.Fragment>
                                                    {t(
                                                      "You can see all the messages before the last edit"
                                                    )}
                                                  </React.Fragment>
                                                ),
                                              })
                                            );
                                          }

                                          index === 3 &&
                                            dispatch(
                                              openModal("delete-modal", {
                                                id: "delete-message",
                                                messageId: message._id,
                                                messages: messagesStored,
                                                websiteID: user?.websiteID,
                                                icon: trashModalIcon,
                                                title: t(
                                                  "Are you sure to delete this message?"
                                                ),
                                                description: (
                                                  <React.Fragment>
                                                    {t(
                                                      "by clicking on delete message this message will"
                                                    )}
                                                    <span>
                                                      &nbsp;{t("Permanently")}
                                                      &nbsp;
                                                    </span>
                                                    {t(
                                                      "deleted and you will not be able to recover it!"
                                                    )}
                                                  </React.Fragment>
                                                ),
                                              })
                                            );
                                        }}
                                      />
                                    );
                                  })()
                                : index !== 3 && (
                                    <LazyLoadImage
                                      src={item.icon}
                                      key={item.id}
                                      alt="icon"
                                      onClick={() => {
                                        if (index === 0) {
                                          setEdit(true);

                                          message?.file &&
                                            setEditedFile(message?.file);
                                          setIsHovering(false);
                                        }
                                        if (index === 2) {
                                          setTodo(message);
                                        }
                                        if (index === 1) {
                                          dispatch(
                                            openModal("info-modal", {
                                              id: "edit-message-history",
                                              messages: message?.history,
                                              icon: editIcon,
                                              title: t("Edit Message History"),
                                              description: (
                                                <React.Fragment>
                                                  {t(
                                                    "You can see all the messages before the last edit"
                                                  )}
                                                </React.Fragment>
                                              ),
                                            })
                                          );
                                        }
                                      }}
                                    />
                                  );
                            } else {
                              return (
                                index !== 0 &&
                                index !== 3 && (
                                  <LazyLoadImage
                                    src={item.icon}
                                    key={item.id}
                                    alt="icon"
                                    onClick={() => {
                                      if (index === 2) {
                                        setTodo(message);
                                      }
                                      if (index === 1) {
                                        dispatch(
                                          openModal("info-modal", {
                                            id: "edit-message-history",
                                            messages: message?.history,
                                            icon: editIcon,
                                            title: t("Edit Message History"),
                                            description: (
                                              <React.Fragment>
                                                {t(
                                                  "You can see all the messages before the last edit"
                                                )}
                                              </React.Fragment>
                                            ),
                                          })
                                        );
                                      }
                                    }}
                                  />
                                )
                              );
                            }
                          })}
                        </div>
                      )}
                  </div>
                </Tooltip>

                {message?.error &&
                  (statusResendMessage === "loading" &&
                  message?.status === "loading" ? (
                    <CircularProgress className="circular-progress-resend-message" />
                  ) : (
                    <div
                      className={
                        statusResendMessage === "loading"
                          ? "resend-unsend-message disabled-resend-icon"
                          : "resend-unsend-message"
                      }
                      onClick={() =>
                        resendMessage(
                          parseHtml(message.content).includes("+?+?+")
                            ? parseHtml(message.content)
                                .split("+?+?+")
                                .map((item, idx) => {
                                  if (isImage(item)) {
                                    return (
                                      <FileMessage
                                        className="message-file"
                                        file={item}
                                        fileType={"image"}
                                        key={idx}
                                      />
                                    );
                                  } else {
                                    return (
                                      <div key={idx}>{breakLiner(item)}</div>
                                    );
                                  }
                                })
                            : isIframe(message.content).includes("#?#?#")
                            ? isIframe(message.content)
                                .split("#?#?#")
                                .map((item, idx) => {
                                  if (item.includes("https")) {
                                    return (
                                      <FileMessage
                                        className="message-file"
                                        file={item}
                                        fileType={"iframe"}
                                        key={idx}
                                      />
                                    );
                                  } else {
                                    return (
                                      <div key={idx}>{breakLiner(item)}</div>
                                    );
                                  }
                                })
                            : breakLiner(message.content),
                          message?.fileObject,
                          message?._id
                        )
                      }
                    >
                      <LazyLoadImage src={resendIcon} alt="resend-icon" />
                    </div>
                  ))}
              </React.Fragment>
            )}
          </div>
          {!edit && (
            <div
              className={
                message.from === "OPERATOR" ||
                message.from === "BOT" ||
                message?.from === "AI" ||
                message?.isNote
                  ? "message-bottom-own"
                  : "message-bottom"
              }
            >
              {statusSendMessage === "loading" &&
              messagesStored[messagesStored?.length - 1]?._id === message?._id
                ? ""
                : (() => {
                    return (
                      messagesStored[messagesStored?.length - 1]?._id ===
                        message?._id &&
                      message?.seenByClient &&
                      `${t("Seen By")} ${
                        clientConversationStored?.meta?.name
                          ? clientConversationStored.meta?.name
                          : "Guest"
                      }`
                    );
                  })()}
            </div>
          )}
        </div>
      </div>
    );
  }
);
export default Message;
